<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="保养登记"
      left-arrow
      @click-left="$router.go(-1)"
    ></van-nav-bar>
    <div class="alarm-detail">
      <van-cell title="设备位号" :value="infoForm.equipmentRecordNo" />
      <van-cell title="设备名称" :value="infoForm.equipmentRecordName" />
      <van-cell
        title="设备所属部门"
        :value="infoForm.equipmentDepartmentName"
      />
      <van-cell title="保养类型" :value="infoForm.typeName" />
      <van-cell title="计划保养日期" :value="infoForm.planDate" />
      <van-cell title="保养项目" :value="infoForm.project" />
      <van-cell title="备注" class="cell" />
      <van-field
        v-model="infoForm.remark"
        rows="3"
        type="textarea"
        maxlength="500"
        placeholder="请输入备注"
      />
      <van-cell
        title="保养人"
        required
        :value="infoForm.maintainerName"
        @click="maintainerInfo.show = true"
      />
      <van-cell title="保养人部门" required :value="infoForm.departmentName" />
      <van-cell
        title="实际保养日期"
        required
        :value="surfaceShow"
        @click="dayChose"
      />
      <van-popup
        v-model="actualDateInfo.show"
        position="bottom"
        style="width: 100%; height: 40%"
      >
        <van-datetime-picker
          v-model="infoForm.actualDate"
          type="date"
          title="选择实际保养日期"
          @confirm="dayConfirm"
          @cancel="closePoup"
        />
      </van-popup>

      <van-field class="c-van-field cell" label="现场照片" label-width="120px">
        <template #input>
          <image-uploader
            key="fixInfo-image-uploader"
            ref="image-uploader"
            v-model="fileList"
            :max-count="5"
            @change="onImageChange"
          />
        </template>
      </van-field>
      <bottom-fixed-box>
        <van-button type="primary" @click="submit('preserved')"
          >保存</van-button
        >
        <van-button type="primary" @click="submit('commitRecord')"
          >提交</van-button
        >
      </bottom-fixed-box>
    </div>
    <SelectPopupGrade
      v-model="infoForm.maintainer"
      :immediately="false"
      :title="maintainerInfo.title"
      :list.sync="maintainerInfo.list"
      :visible="maintainerInfo.show"
      @change="val => userCchange(val)"
      @close="maintainerInfo.show = false"
    />
    <select-person
      :title="maintainerInfo.title"
      :visible="maintainerInfo.show"
      :choose-value="maintainerInfo.list"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="true"
      :multiple="false"
      department-disabled-key="--"
      @close="maintainerInfo.show = false"
      @confirm="maintainerChoose"
    />
  </div>
</template>

<script>
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import { serveSave, getInfo } from "@/api/deciveServe";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import { parseTime } from "@/utils";
import { deepClone } from "@/utils/index";

export default {
  name: "AlarmDetail",
  components: {
    BottomFixedBox,
    ImageUploader
  },
  data() {
    return {
      infoForm: {
        equipmentRecordNo: "",
        equipmentRecordName: "",
        equipmentDepartmentName: "",
        typeName: "",
        planDate: "",
        project: "",
        maintainer: "",
        maintainerName: "",
        department: "",
        departmentName: "",
        actualDate: null,
        remark: ""
      },
      fileList: [],
      maintainerInfo: {
        show: false,
        title: "请选择保养人"
      },
      actualDateInfo: {
        show: false,
        title: "请选择实际保养日期"
      },
      surfaceShow: null
    };
  },
  created() {
    this.fileList = [];
    this.init();
  },
  mounted() {
    this.$refs["image-uploader"].setInnerValue(this.infoForm.list);
  },
  methods: {
    init() {
      if (this.$route.params.id) {
        // this.$route.params.id这个id是PC端发起待办时传递的润滑保养记录id
        getInfo(this.$route.params.id).then(res => {
          // 表示从待办跳转至润滑保养详情
          this.infoForm = res;
          this.infoForm.recordId = res.id; // 从待办进入到润滑保养页面需要手动将记录id赋值
          this.infoForm.list = (this.infoForm.files || []).map(i => {
            return {
              ...i,
              url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${i.id}`
            };
          });
          this.infoForm.actualDate = this.infoForm.actualDate
            ? new Date(this.infoForm.actualDate)
            : new Date();
          this.surfaceShow = this.infoForm.actualDate
            ? parseTime(this.infoForm.actualDate, "{y}-{m}-{d}")
            : parseTime(new Date(), "{y}-{m}-{d}");
          this.$refs["image-uploader"].setInnerValue(this.infoForm.list);
        });
      } else {
        // 表示从润滑保养列表跳转至润滑保养详情
        this.infoForm = deepClone(this.$route.params.item); // 需要对传入的数据进行深拷贝，否则会出现在本页面上传了一张图片并未保存得情况下，再次进入页面发现图片未清除
        this.infoForm.list = (this.infoForm.list || []).map(i => {
          return {
            ...i,
            url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${i.id}`
          };
        });
        this.infoForm.actualDate = this.infoForm.actualDate
          ? new Date(this.infoForm.actualDate)
          : new Date();
        this.surfaceShow = this.infoForm.actualDate
          ? parseTime(this.infoForm.actualDate, "{y}-{m}-{d}")
          : parseTime(new Date(), "{y}-{m}-{d}");
      }
    },
    onImageChange(val) {
      this.fileList = val;
    },
    //保养人选择
    maintainerChoose(Id, maintainerAllInfo) {
      this.infoForm.maintainer = maintainerAllInfo[0].id;
      this.infoForm.maintainerName = maintainerAllInfo[0].name;
      this.infoForm.department = maintainerAllInfo[0].department;
      this.infoForm.departmentName = maintainerAllInfo[0].departmentName;
    },
    //打开日期选择弹窗
    dayChose() {
      this.actualDateInfo.show = true;
    },
    //选择实际保养日期
    dayConfirm(val) {
      const y = val.getFullYear();
      const m = val.getMonth() + 1;
      const d = val.getDate();
      const cur = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
      this.infoForm.actualDate = cur;
      this.surfaceShow = cur;
      this.actualDateInfo.show = false;
    },
    //取消日期选择
    closePoup() {
      this.actualDateInfo.show = false;
    },
    // 保存或提交
    submit(controller) {
      if (controller === "commitRecord") {
        // 提交需校验必填项是否输入完整
        if (
          !this.infoForm.maintainer ||
          !this.infoForm.department ||
          !this.infoForm.actualDate
        ) {
          this.$toast.fail("必填项数据缺失,请检查!");
          return false;
        }
      }
      this.infoForm.list = this.fileList.map(i => {
        return {
          id: i.id,
          name: i.name,
          size: i.fileInfo?.size,
          uploadTime: i.fileInfo?.uploadTime
        };
      });
      if (typeof this.infoForm.actualDate === "object") {
        this.infoForm.actualDate = parseTime(
          this.infoForm.actualDate,
          "{y}-{m}-{d}"
        );
      }
      serveSave(controller, this.infoForm).then(res => {
        if (res) {
          this.$toast.success(
            `${controller === "commitRecord" ? "提交" : "保存"}成功！`
          );
          this.$router.push({
            name: "deviceServeList",
            params: { refresh: true }
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .cell::after {
    border: none !important;
  }
}
.page {
  text-align: left;
  height: 100vh;
  background: #fff;
}
.alarm-detail {
  overflow: auto;
  padding: 58px 0 0;
  // height: calc(100vh - 58px);
}
</style>
