import axios from "../utils/axios";
import apiPath from "../utils/apiPath";
const API_SERVE = apiPath.equipment;
//查询润滑保养列表
export const test = params => {
  return axios.get(API_SERVE + "/phone/device/maintain/list", { params });
};
//保存或提交
export const serveSave = (controller, params) => {
  return axios.post(API_SERVE + `/phone/${controller}/maintain`, params);
};

// 查询润滑保养详情
export const getInfo = id => {
  return axios.get(API_SERVE + `/info/device/maintain/record/${id}`);
};
